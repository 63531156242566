<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root' && rol != 'admin' && rol != 'admin-empresa'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>      
        <v-container xl fluid v-else>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Prestaciones'"
                        :columns="columns"
                        :items="tableData.data"
                        :paginationInfo="tableData.paginatorInfo"
                        @pagination="getPaginationInfo"
                        :showAdvancedFilters="true"
                        @loadModalData="loadModalData"
                        :filters="filters"
                        @setFilters="setFilters"
                        :perPage="[10,25,50,100]"
                        :loading="isLoading"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar" v-if="permisos.create">
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>
                        <template slot="filters">
                            <v-col xs="12" sm="12" md="12" class="py-0" >
                                <v-text-field
                                outlined
                                label="Nombre"
                                v-model="nombre"
                                ></v-text-field>
                            </v-col>
                            <template v-if="rol == 'root'">
                <v-col xs="12" sm="6" md="6" class="py-0">
                  <v-autocomplete
                    outlined
                    :items="clientes"
                    item-text="nombre"
                    item-value="id"
                    label="Cliente"
                    persistent-hint
                    v-model="cliente_value"
                  ></v-autocomplete>
                </v-col>
              </template>

              <template v-if="rol == 'root' || rol == 'admin'">
                <v-col xs="12" sm="6" md="6" class="py-0">
                  <v-autocomplete
                    outlined
                    :items="empresas"
                    item-text="nombre"
                    item-value="id"
                    label="Empresa"
                    persistent-hint
                    v-model="empresa_value"
                  ></v-autocomplete>
                </v-col>
              </template>
                            </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td><div>{{item.nombre}}</div></td>
                                <td>
                                    <div class="tblOpciones">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" class="botonHover" icon fab dark small @click="abrirModal('update', item)">
                                                <v-icon v-show="item.general === 'false'" class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                                <v-icon v-show="item.general === 'true'" class="tamIconoBoton invertirColorBotones">remove_red_eye</v-icon>
                                            </v-btn>
                                            </template>
                                            <span>{{item.general === "false" ? 'Editar':'Visualizar'}}</span>
                                        </v-tooltip>
                                        <v-tooltip bottom v-if="permisos.delete">
                                            <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" v-show="item.general === 'false'" class="botonHover" icon fab dark small @click="eliminar(item)">
                                                <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                            </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <template>
                <v-dialog v-model="dialog" persistent max-width="900px" scrollable>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <perfect-scrollbar style="background: #fff">
                        <v-card elevation="0" class="borde-card">
                            <ValidationObserver ref="form" v-slot="{ invalid }">
                                <v-card-title class="justify-center card_titulo">
                                    <div class="headerModal">
                                        <img :src="imagen" class="imgModal" />
                                        <h2 class="titleModal">{{ tituloModal }}</h2>
                                    </div>
                                </v-card-title>

                                <v-card-text class="card_texto">
                                    <div id="padre" v-show="isLoadingModal">
                                        <div id="loading">
                                            <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                        </div>
                                    </div>
                                    
                                    <v-container v-show="!isLoadingModal" grid-list-md id="contenedor">
                                        <v-divider class="divider"></v-divider>
                                        <v-row>

                                            <template v-if="editar">
                                                <template v-if="rol == 'root'">
                                                    <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0">
                                                        <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                            <v-autocomplete
                                                                outlined
                                                                label="Cliente"
                                                                :items="clientes"
                                                                item-text="nombre"
                                                                item-value="id"
                                                                persistent-hint
                                                                v-model="cliente_value"
                                                                class="pa-0 ma-0"
                                                                required 
                                                                :error-messages="errors" 
                                                                v-if="prestacion.general === false"
                                                            >
                                                            </v-autocomplete>
                                                        </ValidationProvider>
                                                    </v-col>

                                                    <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0">
                                                        <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                                            <v-autocomplete
                                                                outlined
                                                                label="Empresa"
                                                                :items="empresas"
                                                                item-text="nombre"
                                                                item-value="id"
                                                                persistent-hint
                                                                v-model="empresa_value"
                                                                class="pa-0 ma-0"
                                                                required 
                                                                :error-messages="errors" 
                                                                v-if="prestacion.general === false"
                                                            >
                                                            </v-autocomplete>
                                                        </ValidationProvider>
                                                    </v-col>
                                                </template>
                                                <template v-if="rol == 'admin'">
                                                    <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0">
                                                        <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                                            <v-autocomplete
                                                                outlined
                                                                label="Empresa"
                                                                :items="empresas"
                                                                item-text="nombre"
                                                                item-value="id"
                                                                persistent-hint
                                                                v-model="empresa_value"
                                                                class="pa-0 ma-0"
                                                                required 
                                                                :error-messages="errors" 
                                                            >
                                                            </v-autocomplete>
                                                        </ValidationProvider>
                                                    </v-col>
                                                </template>
                                            </template>

                                            <template v-if="rol == 'admin' || rol == 'root'">
                                                <v-col cols="12" xs="12" sm="12" md="8" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                                                        <v-text-field 
                                                            outlined
                                                            label="Nombre" 
                                                            class="pa-0 ma-0" 
                                                            v-model="prestacion.nombre" 
                                                            :error-messages="errors" 
                                                            required
                                                            :disabled="!editar"
                                                        >
                                                        </v-text-field>
                                                    </ValidationProvider>
                                                </v-col>
                                            
                                                <v-col xs="12" sm="12" md="4" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Días de aguinaldo" rules="required">
                                                        <v-text-field
                                                            outlined
                                                            label="Días de aguinaldo"
                                                            v-model="prestacion.dias_aguinaldo"
                                                            v-mask="'###'"
                                                            :error-messages="errors"
                                                            :disabled="!editar"
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                            </template>

                                            <template v-else>
                                                 <v-col xs="12" sm="12" md="8" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                                                        <v-text-field
                                                            outlined
                                                            label="Nombre"
                                                            v-model="prestacion.nombre"
                                                            :error-messages="errors"
                                                            :disabled="!editar"
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col xs="12" sm="12" md="4" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Días de aguinaldo" rules="required">
                                                            <v-text-field
                                                                outlined
                                                                v-mask="'###'"
                                                                label="Días de aguinaldo"
                                                                v-model="prestacion.dias_aguinaldo"
                                                                :error-messages="errors"
                                                                :disabled="!editar"
                                                            />
                                                    </ValidationProvider> 
                                                </v-col>
                                            </template>

                                            <!-- <v-col xs="12" md="12" class="division"></v-col> -->

                                            <v-col cols="12" xs="12" md="12">
                                                <v-divider></v-divider>
                                            </v-col>

                                            
                                            <v-col xs="12" md="12">
                                                <h3 class="subtitle-vacaciones">Vacaciones</h3>
                                            </v-col>

                                            <!--  Ramo de Vacaciones  -->
                                            <template v-if="editar">
                                                <v-col cols="12" xs="12" sm="2" md="2">
                                                    <v-autocomplete
                                                        outlined
                                                        :items="tipos"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        label="Tipo"
                                                        persistent-hint
                                                        v-model="tipo_vacaciones"
                                                    />
                                                </v-col>

                                                <v-col cols="12" xs="12" sm="2" md="2">
                                                    <v-text-field
                                                        outlined
                                                        label="Días"
                                                        v-mask="'###'"
                                                        v-model="dias_vacaciones"
                                                    />
                                                </v-col>

                                                <v-col cols="12" xs="12" sm="3" md="3">
                                                    <v-text-field
                                                        outlined
                                                        label="Prima vacacional"
                                                        suffix="%"
                                                        min="0"
                                                        max="100"
                                                        v-on:keypress="presicionNumeroPorcentaje($event,porcentajePrima)"
                                                        oninput="if(this.value > 999 || this.value<0) this.value = 0; this.value=this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                                        v-model="porcentajePrima"
                                                    />
                                                </v-col>

                                                <v-col cols="12" xs="12" sm="2" md="2">
                                                    <v-text-field
                                                        outlined
                                                        v-mask="'###'"
                                                        label="Rango inicial"
                                                        v-model="rango_inicio"
                                                    />
                                                </v-col>

                                                <v-col cols="12" xs="12" sm="2" md="2">
                                                    <v-text-field
                                                        outlined
                                                        label="Rango final"
                                                        v-mask="'###'"
                                                        v-model="rango_fin"
                                                        :disabled="!rango"
                                                    />
                                                </v-col>

                                                <v-col cols="12" xs="12" sm="1" md="1">
                                                    <v-btn
                                                        color=" #1E2245"
                                                        elevation="0"
                                                        class="mt-2"
                                                        dark
                                                        fab
                                                        @click="agregarPrestaciones()"
                                                        small
                                                    >
                                                        <v-icon dark>add</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </template>
                                        </v-row>

                                        <v-row v-if="detalles.length > 0">
                                            <v-col
                                            cols="12"
                                            xs="12"
                                            sm="12"
                                            md="12"
                                            class="pt-0 pb-0 ma-0"
                                        >
                                            <table class="tbl-documentos">
                                                <thead class="tbl-header">
                                                    <tr>
                                                        <th class="th-prestaciones" style="border-top-left-radius: 10px; height:54px">Tipo</th>
                                                        <th class="th-prestaciones">Días</th>
                                                        <th class="th-prestaciones">Prima vacacional</th>
                                                        <th class="th-prestaciones">Rango inicial</th>
                                                        <th class="th-prestaciones" :style="editar?'':'border-top-right-radius: 10px;'">Rango final</th>
                                                        <th class="th-prestaciones" v-if="editar" :style="editar?'border-top-right-radius: 10px;':''" >Opciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <template v-for="(detalle, index) in detalles">
                                                        <tr :key="index" class="modal">
                                                            <td class="modal">{{ detalle.tipo }}</td>
                                                            <td class="modal">{{ detalle.dias }}</td>
                                                            <td class="modal">{{ detalle.prima_vacaciones }}</td>
                                                            <td class="modal">{{ detalle.rango_inicio }}</td>
                                                            <td class="modal">{{ (detalle.rango_fin == null)? '-' : detalle.rango_fin }}</td>
                                                            <td class="modal" v-if="editar">
                                                                <v-btn
                                                                    class="botonHover mt-1"
                                                                    icon
                                                                    fab
                                                                    dark
                                                                    small
                                                                    @click="eliminaPrestaciones(index, detalle.id)"
                                                                >
                                                                    <v-icon
                                                                    class="tamIconoBoton invertirColorBotonesDelete"
                                                                    >delete</v-icon
                                                                    >
                                                                </v-btn>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </tbody>
                                            </table>
                                        </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions class="d-flex justify-end card_accion pt-10">
                                    <button
                                        class="btnCerrar"
                                        @click="cerrarModal()" 
                                        :disabled="isSaving"
                                    >
                                        Cancelar
                                    </button>
                                    <v-btn
                                        class="btnGuardar"
                                        @click="guardar()" 
                                        :disabled="invalid || !boolDetalle"
                                        :loading="isSaving"
                                        v-if="(permisos.update) || (permisos.create && permisos.update == false && accion == 'add')"
                                    >
                                        Guardar
                                    </v-btn>
                                </v-card-actions>

                                <div class="px-15 pb-10" v-if="permisos.update == false && accion == 'update'">
                                    <v-alert
                                        dense
                                        outlined
                                        type="error"
                                    >
                                        El usuario no tiene permiso para editar el registro.
                                    </v-alert>
                                </div>
                            </ValidationObserver>
                        </v-card>
                    </perfect-scrollbar>
                </v-dialog>
            </template>

        </v-container>
    </div>
</template>

<script>
import Datatable from '@/components/g-datatable/Datatable.vue';
import queries from '@/queries/Prestaciones';
import clienteAPi from '@/api/clientes';
import empresaApi from '@/api/empresas';
import Notify from '@/plugins/notify';
import apiPrestaciones from '@/api/nominas/prestaciones';
import gFunctions from '@/js/global_functions';

export default {
    components: {
        'data-table': Datatable,
    },
    data() {
        return {
            columns         : [
                {
                    label: 'Nombre',
                    name: 'nombre',
                    filterable: true
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            filters         : {OR:[{column:'GENERAL', value:true},{ AND:[]}]},
            tableData       : {},
            isLoading       : true,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            prestacion                  : {
                id                      : null,
                cliente_id              : null,
                empresa_id              : null,
                nombre                  : null,
                dias_aguinaldo          : null,
                general                 : false
            },
            accion          : null,
            tituloModal     : null,
            dialog          : false,
            isSaving        : false,
            clientes        : [],
            empresas        : [],
            cliente_value   : null,
            empresa_value   : null,
            nombre          : null,
            seleccion       : null,
            editar              : true,
            dias_aguinaldo      : null,
            imagen              : '',
            rol                 : null,
            datosLogin          : null,
            detalles            : [],
            prestacionesEliminadas: [],
            dias_vacaciones     : "",
            tipo_vacaciones     : "",
            rango_inicio        : "",
            rango_fin           : "",
            rango               : false,
            boolDetalle         : false,
            showGuardar         : true,
            tipos: [
                { id: "mes", nombre: "Mes" },
                { id: "años", nombre: "Años" },
                { id: "rango", nombre: "Rango" },
            ],
            isLoadingModal: false,
            porcentajePrima: null,
            permisos :{
                create: false,
                update: false,
                delete: false,
            }
        }
    },
    watch: {
        cliente_value: function(val){
            this.cambiaCliente(val);
        },
        tipo_vacaciones: function(val) {
            if (val != "rango") {
                this.rango = false;
            } else {
                this.rango = true;
            }
        },
        detalles: function(val){
            if(val.length > 0){
                this.boolDetalle = true;
            }
            else{
                this.boolDetalle = false;
            }
        }
    },
    methods: {
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }
            this.isLoading = true;
        },
        listar() {
            this.datosLogin = this.$session.get("usuario");
            this.rol = this.datosLogin.rol.name;
            if (this.rol == "root") {
                this.getClientes();
                this.filters = {OR: [{column: "GENERAL", value: true},{AND: [{column:"CLIENTE_ID",operator:"IS_NOT_NULL"}]}]}
            }

            if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.filters = {OR: [{column: "GENERAL", value: true},{AND: [{column:"CLIENTE_ID",value:this.cliente_value}]}]};
            }

            if (this.rol == "admin-empresa") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.filters = {OR: [{column: "GENERAL", value: true},{AND: [{column:"EMPRESA_ID",value:this.empresa_value}]}]};
            }
        },
        async abrirModal(accion, data = null, editar = null){
            
            this.accion     = accion;
            let self        = this;
            this.isLoadingModal  = true;

            if(accion == "add"){
                this.tituloModal = "Registrar prestaciones.";
                this.imagen     = "/static/modal/prestacionesCreate.svg";
                
            } else if(accion == "update"){
                this.tituloModal = "Actualizar prestaciones.";
                this.imagen     = "/static/modal/prestacionesUpdate.svg";
                await apiPrestaciones.get(data.id).then((response) => {
                    if(response.general){
                        this.showGuardar = false;
                        this.editar = false;
                    }
                    else{
                        this.showGuardar = true;
                        this.editar = true;
                    }  
                    self.prestacion.id                  = response.id;
                    self.prestacion.cliente_id          = response.cliente_id;
                    self.prestacion.empresa_id          = response.empresa_id;
                    self.prestacion.general             = response.general;
                    self.cliente_value                  = response.cliente_id;
                    self.empresa_value                  = response.empresa_id;
                    self.prestacion.nombre              = response.nombre;

                    self.prestacion.dias_aguinaldo = response.prestaciones_info.length > 0 ? response.prestaciones_info[0].dias_aguinaldo : 0;
                    let array = response.prestaciones_info;

                    if (array.length > 0) {
                        for (let i = 0; i < array.length; i++) {
                            let d = {
                                id: array[i].id,
                                dias: array[i].dias_vacaciones,
                                prima_vacaciones:array[i].prima_vacaciones,
                                rango_inicio: array[i].rango_inicial,
                                rango_fin: array[i].rango_final,
                            };

                            if (array[i].rango === true) {
                                d.tipo = "Rango";
                            } else if (array[i].tipoMes === true) {
                                d.tipo = "Mes";
                            } else {
                                d.tipo = "Año";
                            }

                            self.detalles.push(d);
                        }
                    }
                })
                .catch((ex) => {
                    this.respuesta_error(ex);
                });
            }
            this.isLoadingModal = false;
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.resetValue();
        },
     
        resetValue() {
            this.$nextTick(() => {
                this.prestacion             = {
                    id                      : null,
                    cliente_id              : null,
                    empresa_id              : null,
                    nombre                  : null,
                    dias_aguinaldo          : null,
                    general                 : false
                }
                
                if(this.$refs.form != undefined || this.$refs.form != null){
                        this.$refs.form.reset();
                    }
                    if(this.rol == 'root'){
                    this.cliente_value      = null;
                    this.empresa_value      = null;
                    this.empresas           = [];
                    this.nombre = null;
                }

                if(this.rol == 'admin'){
                    this.empresa_value      = null;
                    this.nombre = null;
                } 

                if(this.rol == 'admin-empresa'){
                    this.nombre = null;
                }

               
                this.prestacionesEliminadas = [];
                this.tipo_vacaciones    = "";
                this.dias_vacaciones    = "";
                this.rango_inicio       = "";
                this.rango_fin          = "";
                this.porcentajePrima    = null;
                this.detalles           = [];
                this.showGuardar        = true;
                this.editar = true;

            });
        },

        async loadModalData() {
            
            await this.resetValue();

        },
   
        setFilters() {
            this.filters = { AND:[]};

            let nombre = this.nombre;
            let cliente = this.cliente_value;
            let empresa = this.empresa_value;

            if (this.rol == "admin") {
                cliente = this.datosLogin.cliente_id;
            } else if (this.rol == "admin-empresa") {
                cliente = this.datosLogin.cliente_id;
                empresa = this.datosLogin.empresa_id;
            } 
            
            this.isLoading = true;

            if(nombre != null && nombre != undefined && nombre != ""){
                    this.filters.AND.push({column:'NOMBRE', operator:'ILIKE', value:"%"+nombre+"%"});
                }

            if(cliente != null && cliente != undefined && cliente != ""){
                    this.filters.AND.push({column:'CLIENTE_ID', value:cliente});
                }

            if(empresa != null && empresa != undefined && empresa != ""){
                    this.filters.AND.push({column:'EMPRESA_ID', value:empresa});
                }

            if (this.rol == "admin") {
                if(this.filters.AND.length == 1){
                    this.filters = {OR: [{column: "GENERAL", value: true},{AND: [{column:"CLIENTE_ID",value:this.datosLogin.cliente_id}]}]};
                }
            } 
            else if (this.rol == "admin-empresa") {
                if(this.filters.AND.length == 2){
                    this.filters = {OR: [{column: "CLIENTE_ID", operator:"IS_NULL"},{AND: [{column:'CLIENTE_ID', value:this.datosLogin.cliente_id}]}]};
                    this.filters = {OR: [{column: "CLIENTE_ID", operator:"IS_NULL"},{AND: [{column:'EMPRESA_ID', value:this.datosLogin.empresa_id}]}]};
                }
                
            } 
            
            this.paginationData.numberPage = 1;
            this.$apollo.queries.prestaciones.refetch();
            this.resetValue();
        },
        eliminar(data){
            Notify.Alert(
                "¿Estás seguro de eliminar esta prestación?",
                "Esta acción <b>puede afectar</b> algunas funcionalidades del sistema.",
                "Eliminar",
                () => {
                    this.isLoading = true;
                    apiPrestaciones.delete(data.id).then(response => {
                        Notify.Success("Prestacion eliminada", "La prestacion se han eliminado satisfactoriamente");
                        this.$apollo.queries.prestaciones.refetch();
                    })
                    .catch(err => {
                        this.isLoading = false;
                        gFunctions.errorParse(err.response.data.error);
                    });
                },
                null,
                true,
                true
            )
        },
        getClientes(cliente = null) {
            let param = { activo: true, paginate: false }
            clienteAPi.getClientes(param).then((response) => {
                this.clientes = response.data;
            })
            .catch(error => {
                this.resetValue();
                Notify.ErrorToast(error.response.data.error);
            });
        },
        async cambiaCliente(id, cliente = null) {
            if(this.prestacion.cliente_id != id){
                this.empresa_value = null;
                this.prestacion.empresa_id = null;
            }
            if(id == null){
                return;
            }
            let parametros = { activo: true, paginate: false, cliente_id: id };
            await empresaApi.getEmpresas(parametros).then((response)=>{
                this.empresas = response.data;
                if(this.accion != "add"){
                    this.prestacion.empresa_id = this.empresa_value;
                    
                }
            })
            .catch(error => {
                Notify.ErrorToast(error.response.data.error);
            });
        },
        guardar() {
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }
                if(this.detalles.length == 0){
                    Notify.ErrorToast("Se debe gregar al menos un registro en la seccion de vacaciones.");
                    this.isSaving = false;
                    return;
                }

                let cliente = this.cliente_value;
                let empresa = this.empresa_value;

                if (this.rol == "admin") {
                    cliente = this.datosLogin.cliente_id;
                } else if (this.rol == "admin-empresa") {
                    cliente = this.datosLogin.cliente_id;
                    empresa = this.datosLogin.empresa_id;
                } 

                this.prestacion.cliente_id = cliente;
                this.prestacion.empresa_id = empresa;

                this.prestacion.prestaciones_info = this.detalles;
                this.prestacion.prestaciones_eliminadas = this.prestacionesEliminadas;

                this.isLoading = true;
                if(this.accion === "add") {
                    apiPrestaciones.store(this.prestacion)
                    .then((response) => {
                        Notify.Success("Operación exitosa", "Se han generado las prestaciones satisfactoriemente.");
                        this.$apollo.queries.prestaciones.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        gFunctions.errorParse(err.response.data.error);
                    });
                }
                else if(this.accion === "update") {
                    apiPrestaciones.update(this.prestacion)
                    .then((response) => {
                        Notify.Success("Operación exitosa", "Se ha actualizado las prestaciones satisfactoriamente.");
                        this.$apollo.queries.prestaciones.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        gFunctions.errorParse(err.response.data.error);
                    });
                }
            });
        },
        agregarPrestaciones(){
            if (this.tipo_vacaciones == "") {
                Notify.ErrorToast("Favor de seleccionar un tipo");
                return;
            }

            if (this.tipo_vacaciones == "mes" && this.rango_inicio > 11) {
                Notify.ErrorToast("No se debe de pasar de 11 meses");
                return;
            }

            if (this.rango_inicio == "" || this.dias_vacaciones == "") {
                Notify.ErrorToast("Favor de llenar los campos días y rango inicial");
                return;
            }

            if (this.tipo_vacaciones == "rango" && this.rango_fin == "") {
                Notify.ErrorToast("Es requerido un rango final.");
                return;
            }
            if (this.porcentajePrima == "" || this.porcentajePrima == null) {
                Notify.ErrorToast("Es requerido el porcentaje de prima vacacional.");
                return;
            }
            if(parseFloat(this.porcentajePrima) < 25){
                Notify.ErrorToast("El porcentaje de la prima vacacional debe ser mayor al 25%.");
                return;
            }
            
            let prima = (parseFloat(this.porcentajePrima) * parseFloat(this.dias_vacaciones)) / 100;


            let detalle = {
                id              : 0,
                tipo            : this.tipo_vacaciones,
                dias            : this.dias_vacaciones,
                prima_vacaciones: prima,
                rango_inicio    : this.rango_inicio,
                rango_fin       : this.rango_fin,
            };
            this.detalles.push(detalle);
            this.dias_vacaciones    = "";
            this.rango_inicio       = "";
            this.rango_fin          = "";
            this.porcentajePrima    = null;
        },
        eliminaPrestaciones(index,id){
            let id_pres = {id:id};
            this.prestacionesEliminadas.push(id_pres);
            this.detalles.splice(index, 1);
        },
        respuesta_error(err) {
            let error = Object.values(err.response.data.error);
            let leyenda = ``;
            for (var i = 0; i < error.length; i++) {
                leyenda += `* ` + error[i] + `\n`;
            }
            Notify.ErrorToast(leyenda);
        },
        presicionNumeroPorcentaje(event, valor){
            if (/\.\d{2}/.test(valor)) 
                return event.preventDefault();
        },
        redireccionar(){
            this.$router.push('/dashboard');
        },
        permisosVista(){
            let permisosStorage = JSON.parse(sessionStorage.getItem("permisos"))
            this.permisos.create = permisosStorage.includes("prestaciones.store")
            this.permisos.update = permisosStorage.includes("prestaciones.update")
            this.permisos.delete = permisosStorage.includes("prestaciones.delete")
        },

    },
    created() {
        this.listar();
        this.permisosVista();
    },
    mounted() {
        this.$apollo.queries.prestaciones.skip = false;
    },
    apollo: {
        prestaciones: {
            query       : queries.prestacionesTableQuery,
            fetchPolicy : 'network-only',
            variables() {   
                this.isLoading =  true;
                return {
                    whereConditions : this.filters,
                    numberItems : this.paginationData.numberItems,
                    numberPage  : this.paginationData.numberPage,
                    fieldOrder  : this.$refs.tabla.sortKey ? this.$refs.tabla.sortKey : 'nombre',
                    Order       : this.$refs.tabla.Order,
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                this.isLoading  = false;
                this.loading    = false;
                this.tableData  = this.prestaciones;   
            },
        }
    }
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
    .division {
        border-top: 1px solid #c4c4c4;
    }
    th[class="modal"],
    td[class="modal"] {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #96999a;
        line-height: 17px;
        padding: 8px;
        text-transform: capitalize;
        text-align: left !important;
        padding-bottom: 20px;
        padding-top: 20px;
        padding-left: 22px;
    }

    tbody tr[class="modal"]:nth-child(1n) {
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: #c4c4c4;
    }

    .tbl-documentos {
        border-collapse: collapse;
        width: 100%;
        margin-top: 15px;
        /* white-space: nowrap; */
        overflow-x: scroll;
        overflow-y: scroll;
    }

    .tbl-documentos .tbl-header {
        background: #F3F4F6;
        border-radius: 20px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
    .tbl-header tr{
        height: 35px !important;
    }
    .subtitle-vacaciones {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.1px;
        color: #101c5a;
    }
    .th-prestaciones{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #828282;
        background: #F3F4F6;;
        text-align: left !important;
        padding-left: 22px;
    }
</style>
